// @flow

import { Card, Col, Row } from 'antd';
import {
  CreditCardOutlined,
  MailOutlined,
  SafetyOutlined
} from '@ant-design/icons';

import Link from '../Link';
import React from "react";

const {Meta} = Card;

const SecurityView = () => {

  return (
    <>
      <h2 className='ant-typography text-center'>
        Security is our highest priority
      </h2>
      <Row className='top-space-2x' gutter={24}>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              avatar={<CreditCardOutlined style={{fontSize: '32px'}}/>}
              title='Stress-free payments'
              description={<p className='ant-typography'>
                Implementing a safe checkout flow is hard. That is why we delegate this task to <Link rel="noopener noreferrer" target='_blank' to='https://stripe.com/docs/security/stripe'>Stripe</Link> and <Link rel="noopener noreferrer" target='_blank' to='https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security'>Paypal</Link> - the best software platforms for internet commerce. Because of these providers, your card details never reach PostSeal servers.
              </p>}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              avatar={<MailOutlined style={{fontSize: '32px'}}/>}
              title='Sensitive mail data taken care of'
              description={<p className='ant-typography'>Your letter files are temporarily stored in a private Amazon AWS S3 bucket until the letter is sent. PostSeal follows <Link rel="noopener noreferrer" target='_blank' to='https://docs.aws.amazon.com/AmazonS3/latest/dev/security-best-practices.html'>Amazon's security best practices</Link>. Printing and mailing services are provided by <Link rel="noopener noreferrer" target='_blank' to='https://www.clicksend.com/gb/api-docs/security-compliance'>ClickSend</Link> and <Link rel="noopener noreferrer" target='_blank' to='https://lob.com/security#incident-detection-response'>Lob</Link>, which take security very seriously.</p>}
            />
          </Card>
        </Col>
        <Col xs={24} md={12} >
          <Card bordered={false} className='light'>
            <Meta
              avatar={<SafetyOutlined style={{fontSize: '32px'}}/>}
              title='Secure network communication'
              description={<p className='ant-typography'>All data requests are sent and received via <Link rel="noopener noreferrer" target='_blank' to='https://en.wikipedia.org/wiki/HTTPS'>HTTPS</Link> (Hypertext Transfer Protocol Secure), where the data flow between client and server is encrypted. </p>}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SecurityView;
